














import { Component, PropSync, Vue } from 'vue-property-decorator'

import { HeadingForm } from '../../../../molecules'
import { MarkdownEditor } from '../../../../molecules/MarkdownEditor'

import { EventEntryData } from '../../Timeline.contracts'

@Component<EventForm>({
  name: 'EventForm',
  components: { MarkdownEditor, HeadingForm }
})
export class EventForm extends Vue {
  @PropSync('model', { type: Object, required: true })
  public _model!: EventEntryData
}

export default EventForm
