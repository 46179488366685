// Copyright © 2021 Move Closer

import { VueConstructor } from 'vue'

import { EntryType } from '../Timeline.contracts'

import DividerForm from './partials/DividerForm.vue'
import EventForm from './partials/EventForm.vue'

export const formComponentMap: Record<EntryType, VueConstructor> = {
  [EntryType.Divider]: DividerForm,
  [EntryType.Event]: EventForm
}
