

















import { VueConstructor } from 'vue'
import { Component } from 'vue-property-decorator'

import { Iterator, MapSelector } from '../../../molecules'

import { AbstractModuleForm } from '../../_abstract'

import { timelineContentFactory } from '../Timeline.factory'
import {
  DividerEntryData,
  Entry,
  EntryType,
  EventEntryData,
  TimelineModule,
  TimelineModuleContent
} from '../Timeline.contracts'

import { formComponentMap } from './Timeline.form.helpers'

/**
 * TODO: Document all methods and properties.
 * TODO: Add `@author` annotation for the original author of the file.
 *
 * @author ??? (original)
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 */
@Component({
  name: 'TimelineModuleForm',
  components: { Iterator, MapSelector }
})
export class TimelineModuleForm extends AbstractModuleForm<TimelineModule> {
  public initialContent: TimelineModuleContent = timelineContentFactory()

  public readonly types = EntryType

  public addNewElement (type: EntryType): Entry {
    let data: DividerEntryData | EventEntryData

    switch (type) {
      case EntryType.Divider:
        data = this.createDividerData()
        break
      case EntryType.Event:
        data = this.createEventData()
    }

    return {
      data: data,
      type: type
    }
  }

  public getItemComponent (type: EntryType): VueConstructor {
    if (!formComponentMap[type]) {
      throw new Error(`[TimelineModuleForm] no component register for type [${type}]`)
    }

    return formComponentMap[type]
  }

  protected createDividerData (): DividerEntryData {
    return {
      content: ''
    }
  }

  protected createEventData (): EventEntryData {
    return {
      body: ''
    }
  }
}

export default TimelineModuleForm
