
































import { DashmixSelectItem } from '@movecloser/ui-core'
import { Component, PropSync, Vue } from 'vue-property-decorator'

import { HeadingLevel } from '../../../../../contracts'
import { MarkdownEditor } from '../../../../molecules/MarkdownEditor'

import { DividerEntryData } from '../../Timeline.contracts'

@Component<DividerForm>({
  name: 'DividerForm',
  components: { MarkdownEditor }
})
export class DividerForm extends Vue {
  @PropSync('model', { type: Object, required: true })
  public _model!: DividerEntryData

  public get headingOptions (): DashmixSelectItem[] {
    return [...Object.entries(HeadingLevel).map(([name, value]) => {
      return {
        label: this.$t(`formHelpers.headingLevel.options.${name}`).toString(),
        value
      }
    })
    ].filter(option => typeof option.value === 'number')
  }
}

export default DividerForm
